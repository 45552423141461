const urlBaseResources = process.env.REACT_APP_RESOURCES_BASE_URL;
const urlBaseResourcesMobile = process.env.REACT_APP_RESOURCES_MOBILE_BASE_URL;

export const resources = {
  banksImg: `${urlBaseResources}/img/banks/`,
  logos: `${urlBaseResources}/img/logos/`,
  flags: `${urlBaseResources}/img/flags/`,
  paymentMethods: `${urlBaseResources}/img/payment_methods/wide/`,
  paymentMethodsMobile: `${urlBaseResourcesMobile}/img/payment_methods/`,
  refunds: `${urlBaseResourcesMobile}/img/refund_methods/`,
  banks: `${urlBaseResourcesMobile}/img/banks/`,
  merchants: `${urlBaseResourcesMobile}/img/merchants/`,
  resellers: `${urlBaseResources}/img/resellers/`,
  brands: `${urlBaseResourcesMobile}/img/brands/`,
  regions: `${urlBaseResourcesMobile}/img/regions/`,
};

const urls = {
  login: '/v1/resellers/login',
  authMfa: '/v1/resellers/login/mfa',
  createMfa: '/v1/resellers/mfa/create',
  validateMfa: '/v1/resellers/mfa/validate',
  forgotPass: '/v1/resellers/forgotPassword',
  recoveryPassword: '/v1/resellers/changePassword',
  logout: '/v1/resellers/logout',

  userProfile: '/v1/resellers/profile',

  dashboard: 'v1/resellers',
  dashboardMetrics: '/v1/resellers/metrics/home',

  getPayments: '/v1/resellers/payments',
  getPaymentDetails: '/v1/resellers/payments/:paymentId',
  getPaymentMethods: '/v1/resellers/paymentMethods/:paymenMethodCode',
  approvePayment: '/v1/resellers/payments/release',
  cancelPayment: '/v1/resellers/payments/cancel',

  // Money Service
  getCardInfo: '/v1/resellers/funds/cards/creation/info',
  getCurrencyExchange: '/v1/resellers/funds/currencies/convert',
  createCard: '/v1/resellers/funds/cards',
  createAssignedCard: '/v1/resellers/funds/cards/assigned',
  createIssuanceCard: '/v1/resellers/funds/issuance/create',
  cardPreview: '/v1/resellers/funds/issuance/preview',

  getCardTransactions: '/v1/resellers/funds',
  cancelCardTransaction: '/v1/resellers/funds/issuance/{issuance_id}/cancel',
  getCardTransactionDetail: '/v1/resellers/deposits/cards/{cardTransactionsId}',
  getIssuanceGroup: '/v1/resellers/funds/issuance/groups',
  getCardsFromGroup: '/v1/resellers/funds/issuance/groups/{group_id}',
  getCardsFromGroupDownload: '/v1/resellers/funds/issuance/groups/{group_id}/download',

  getMessages: '/v1/resellers/chat/messages',
  sendMessages: '/v1/resellers/chat/messages/send',
  markMessagesAsRead: '/v1/resellers/chat/messages/markAsRead',

  countries: '/v1/resellers/country',

  getContacts: '/v1/resellers/contacts',
  createContact: '/v1/resellers/contacts/create',
  deleteContact: '/v1/resellers/contacts/delete',

  getUsdtAddress: '/v1/reseller/wallet/address',
  getWallet: '/v1/resellers/balance/request',
  getNewWallet: '/v1/resellers/balance/request/new',
  getCryptoDeposits: '/v1/resellers/balance/requests',
  getExchange: '/v1/resellers/cashout/:walletValue',
  cashout: '/v1/resellers/cashout',

  validateEmail: '/v1/resellers/transfer/email/validate',
  validateEmailSubtract: '/v1/resellers/transfer/email/validate/subtract',
  transfer: '/v1/resellers/transfer',
  transferSubtract: '/v1/resellers/transfer/subtract',
  transferHistory: '/v1/resellers/transfer/all',
  transferResellerConfiguration: '/v1/resellers/transfer/allowed/{email}',
  addResellerConfiguration: '/v1/resellers/transfer/save',

  getResellers: '/v1/resellers/all',
  getSubResellers: '/v1/resellers/info/{requestedResellerId}/subresellers',
  getResellersDetails: '/v1/resellers/info/{requestedResellerId}',
  editReseller: '/v1/resellers/edit',
  createReseller: '/v1/resellers/create',
  disableMfaReseller: '/v1/resellers/{reseller_id}/deactivateMfa',
  deactivateReseller: '/v1/resellers/{reseller_id}/deactivate',
  activateReseller: '/v1/resellers/{reseller_id}/activate',

  cashInVerify: '/v1/resellers/cards/cashIn/verify',
  cashInVerifyCode: '/v1/resellers/cards/cashIn/verify/code',
  cashInVerificationCode: '/v1/resellers/cards/cashIn/user-code',
  cashInLoad: '/v1/resellers/cards/cashIn',
  cashInLoadCode: '/v1/resellers/cards/cashIn/code',
  cashInCardsHistory: '/v1/resellers/cards/cashIn/history',
  cashInWalletHistory: '/v1/resellers/wallet/cashIn/history',
  cashinRequests: '/v1/resellers/cards/cashIn/history/manual',
  cashinApproveManagerRequests: '/v1/resellers/cards/cashIn/manager/approve/:cashId',
  cashinApproveFinanceRequests: '/v1/resellers/cards/cashIn/finance/approve/:cashId',
  cashinDeniedRequests: '/v1/resellers/cards/cashIn/denied/:cashId',

  addSource: '/v1/resellers/source/add',
  getResellerSpreads: '/v1/resellers/spread/{reseller_id}/list',
  addSpread: '/v1/resellers/spread/{reseller_id}/add',
  editSpread: '/v1/resellers/spread/{reseller_id}/update',
  removeSpread: '/v1/resellers/spread/{reseller_id}/remove',

  getRequests: '/v1/resellers/requests',
  getRequestDetails: '/v1/resellers/requests/{request_id}',
  approveRequest: '/v1/resellers/requests/status/approve',
  updateRequest: '/v1/resellers/requests/status/update',
  createRequest: '/v1/resellers/requests/create',
  createNotification: '/v1/resellers/notifications/custom',
  validateRequestEmail: '/v1/resellers/requests/validation',

  getNotifications: '/v1/resellers/notifications',
  getImportantNotifications: '/v1/resellers/notifications/important',
  getNotificationDetail: '/v1/resellers/notifications/:notificationId',
  setNotificationAsRead: '/v1/resellers/notifications/markAsRead',

  validateEmailCompensation: '/v1/resellers/compensation/validate/:email',
  validateResellerDepositId: '/v1/resellers/compensation/validate/deposit/:resellerDepositId',
  saveCompensation: '/v1/resellers/compensation/execute',
  compensationHistory: '/v1/resellers/compensation/list',

  getTellers: '/v1/resellers/payments/teller/all',
  getTellerDetails: '/v1/resellers/payments/teller/information/:email',
  getTellerPaymentMethodDetails: '/v1/resellers/payments/teller/information/:email/:id',
  updateTeller: '/v1/resellers/payments/teller/update',
  updateTellerPaymentMethodDetails: '/v1/resellers/payments/teller/update/configuration',
  saveTellerPaymentMethodDetails: '/v1/resellers/payments/teller/save/configuration',
  getTellerPaymentMethodList: '/v1/resellers/payments/paymentMethods/all',
  getPaymentMethodList: '/v1/reseller/cellulant/paymentMethod',
  cellulantSubmit: '/v1/reseller/cellulant/create',
  netellerSubmit: '/v1/reseller/neteller/create',
  processorSubmit: '/v1/reseller/pg/create',
  allowedPaymentMethod: '/v1/reseller/wallet/address',
  getPayvalidaPaymentMethodList: '/v1/reseller/payvalida/payment-methods',
  permissions: '/v1/resellers/permissions',
  savePermissions: '/v1/resellers/permissions/save',

  addIp: '/v1/resellers/ip/add',
  removeIp: '/v1/resellers/ip/remove',

  getProducts: '/v1/reseller/bonoxs/productsByRegion',
  bonusBoxPurchase: '/v1/reseller/bonoxs/purchase',
  getBoxBonusOrderHistory: '/v1/reseller/bonoxs/orders',
  addPhone: '/v1/resellers/phone/add',
  getLogs: '/v1/resellers/logs/all',
  getMovements: '/v1/resellers/logs/reseller-movements',
  getMovementTypeList: '/v1/resellers/logs/movements',
  compensationInfoBitstamp: '/v1/resellers/compensation/information/bitstamp/:tx_id',
  compensationProcessBitstamp: '/v1/resellers/compensation/process/bitstamp/:tx_id',

  getMerchants: '/v1/resellers/funds/issuance/merchants',

  getCommissions: '/v1/deposit/commission',
  downloadCommissionsFile: '/v1/deposit/commission/export',
};

export default urls;
